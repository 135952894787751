import AuthenticationResult from "../models/AuthenticationResult"

const sendPin = async (pin: string, language: string): Promise<AuthenticationResult> => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_CUSTOMER_API}/api/authentication/Authenticate?pin=${pin}&uiLocale=${language}`,
      {
        method: 'POST',
        credentials: 'include'
      })
      .then(res => res.json())
      .then(json => resolve(json))
      .catch(err => reject(err));
    });
}

const sendResponseToBackend = (params: string) => {
  const url = `${process.env.REACT_APP_CUSTOMER_API}/api/authentication/Return${params}`;
  fetch(url, { method: 'POST', credentials: 'include' })
    .then(res => res.json())
    .then(res => {
      if (res.url) {
        window.location.assign(res.url);
      } else {
        window.location.replace(`${process.env.REACT_CUSTOMER_FRONTEND_URL!}`);
      }

    })
}

export { sendPin, sendResponseToBackend }