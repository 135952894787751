import { useContext } from 'react';
import LinkSvg from '../../images/LinkSvg';
import { LanguageContext } from '../../languages/languages';
import Text from '../../languages/Text';
import './Footer.css';

const Footer = () => {
  const languageContext = useContext(LanguageContext);
  return (
    <footer>
      <div className="footer-container">
        <ul className='footer-links'>
          <li><a className='footer-link' href={languageContext.dictionary.cookiesLink}><Text tid='cookies'/>  <LinkSvg /></a></li>
          <li><a className='footer-link' href={languageContext.dictionary.termsLink}><Text tid='terms'/> <LinkSvg /></a></li>
          <li><a className='footer-link' href={languageContext.dictionary.userInfoLink}><Text tid='userInfo'/> <LinkSvg /></a></li>
          <li><a className='footer-link' href={languageContext.dictionary.accessibilityLink}><Text tid='accessibility'/> <LinkSvg /></a></li>
          <li><a className='footer-link' href={languageContext.dictionary.safetyLink}><Text tid='safety'/>  <LinkSvg /></a></li>
        </ul>
        <span className='footer-copyright'><Text tid='copyright'/></span>
      </div>
    </footer>
  )
}

export default Footer