import { FormEvent, useContext, useRef, useState } from "react";
import { sendPin } from "../../api/AuthenticationApi";
import Error from "../../components/Error/Error";
import { LanguageContext } from "../../languages/languages";
import Text from "../../languages/Text";
import "./Pin.css";

const Pin = () => {
  const [pin, setPin] = useState<string>("");
  const [hasError, setHasError] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const language = useContext(LanguageContext);

  const pinFocus = useRef<HTMLInputElement>(null);

  const onInputChange = (event: any) => {
    const re = /^[0-9\b]+$/;
    if (hasError && event.target.value.length < 3) {
      setHasError(false);
    }
    if (event.target.value === '' || re.test(event.target.value)) {
      setPin(event.target.value);
    }
  }

  const submit = (event: FormEvent) => {
    event.preventDefault();
    if (pin.length !== 3) return;
    setIsAuthenticating(true);
    sendPin(pin, language.userLanguage)
      .then((res: any) => {
        setIsAuthenticating(false);
        if (res.url) {
          setHasError(false);
          window.location.assign(res.url);
          return;
        }
        if (res.status !== 200) {
          setHasError(true);
          pinFocus.current?.focus();
          return;
        }
      })
      .catch(err => {
        setHasError(true);
        setIsAuthenticating(false);
        pinFocus.current?.focus();
        return;
      })
  }

  return (
    <>
      <div className="pin-container">
        <p><Text tid="prompt1" /> <br /> <Text tid="prompt2" /></p>
        <form onSubmit={submit} autoComplete='off'>
          <label>
            <input 
            ref={pinFocus}
            name="pin" 
            aria-label={language.dictionary.pinAccessibility} 
            className={hasError ? 'pin-input error' : 'pin-input'} 
            value={pin} 
            onChange={onInputChange} 
            type="text" 
            maxLength={3} 
            autoComplete='off'
            aria-describedby="pin-error"
            aria-required
            aria-live="assertive">
            </input>
          </label>
          <button
            disabled={hasError || pin.length < 3}
            className={hasError || pin.length < 3 ? 'pin-ok disabled' : 'pin-ok'}
            type="submit"
            onSubmit={submit}><Text tid="continue" /></button>
        </form>
        {isAuthenticating && <p role="alert"><Text tid="authenticating" /></p> /*Tähän joku paremman näköinen hommeli vielä*/}
        {hasError && <Error describedBy="pin-error"/>}
      </div>
    </>
  );
}

export default Pin;