import { useEffect, useState } from 'react'
import { allowedLanguages, dictionaryList, LanguageContext } from './languages';

const LanguageProvider = ({children}) => {
  const [userLanguage, setUserLanguage] = useState('fi');
  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    let language = params.lang?.toLowerCase() ?? 'fi';
    language = allowedLanguages.includes(language) ? language : 'fi';
    setUserLanguage(language);
  }, [])

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      setUserLanguage(selected);
    }
  }

  useEffect(() => document.title = provider.dictionary["tabTitle"], [userLanguage]);

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
