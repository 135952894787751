import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Pin from './routes/pin/Pin';
import Confirmed from './routes/confirmed/Confirmed';
import Redirect from './routes/redirect/Redirect';
import LanguageProvider from './languages/LanguageProvider';

ReactDOM.render(
  <React.StrictMode>
    <LanguageProvider>
      <BrowserRouter>
      <Routes>
        <Route path='/eident/return/' element={<Redirect />}></Route>
        <Route path='/' element={<App />}>
          <Route index element={<Pin />}></Route>
          <Route path='/Confirmed' element={<Confirmed />}></Route>
        </Route>
        <Route path='*' element={<Navigate to='/'></Navigate>}></Route>
      </Routes>
      </BrowserRouter>
    </LanguageProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
