import { createContext } from 'react';
import fi from './fi.json';
import sv from './sv.json';

export const dictionaryList: any = { 
  fi, 
  sv
};

export const allowedLanguages = ['fi', 'sv'];

export const LanguageContext = createContext({
  userLanguage: 'fi',
  dictionary: dictionaryList.fi
});
