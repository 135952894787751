import { Outlet } from 'react-router-dom';
import './styles/App.css';
import Footer from './components/Footer/Footer';
import Text from './languages/Text';
import Logo from './images/logo.svg';
import { LanguageContext } from './languages/languages';
import { useContext } from 'react';


const App = () => {
  const languageContext = useContext(LanguageContext);
  return (
    <>
      <header>
        <div className="header-container">
          <a href={languageContext.dictionary.customerHomeLink} className='navigation' ><Text tid="customerHome"/></a>
          <a href={languageContext.dictionary.customerHomeLink} title={languageContext.dictionary.customerHomeTitle} ><img src={Logo} alt="Handelsbanken logo" className='logo'/></a>
        </div>
      </header>
      <main>
        <div className='main-container'>
          <h1 className='title'><Text tid="title" /></h1>
          <Outlet />
        </div>
      </main>
      <Footer />
    </>
  )
}

export default App;
