import OkSvg from '../../images/OkSvg';
import Text from '../../languages/Text';
import './Confirmed.css'

const Confirmed = () => {
  return (
    <>
      <br />
      <br />
      <OkSvg />
      <br />
      <br />
      <p><Text tid='confirmed'/></p>
    </>
  )
}

export default Confirmed