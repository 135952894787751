const OkSvg = () => {
  return (
    <svg width="160" height="160" viewBox="0 0 380 380" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="190" cy="190" r="189" stroke="#005FA5" strokeWidth="2" />
      <line x1="149.318" y1="247.269" x2="269.908" y2="134.741" stroke="#005FA5" strokeWidth="2" />
      <path d="M106.261 201.096L150.103 247.904" stroke="#005FA5" strokeWidth="2" />
    </svg>
  )
}

export default OkSvg