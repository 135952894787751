const LinkSvg = () => {
  return (
    <svg focusable="false" width="11" height="11" viewBox="0 0 11 11" version="1.1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <g transform="translate(0.564 0.567)">
        <path d="M3.885,3.5H.5v9.863h9.863V10.032" transform="translate(-0.5 -3.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"></path>
        <path d="M0,9.377,5.931,3.533" transform="translate(3.598 -3.095)" fill="none" stroke="#fff" strokeWidth="1"></path>
        <path d="M9.5.5h3.683V4.183" transform="translate(-3.32 -0.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"></path>
      </g>
    </svg>
  )
}

export default LinkSvg