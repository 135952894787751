import './Error.css'
import Text from '../../languages/Text';

const Error = (props: {message?: string, describedBy?: string}) => {
  return (
    <div className='error-container'>
      <div className="error-messages">
        <p className='error bold' id={props.describedBy} role="alert">{props.message ?? <Text tid="error" />}</p>
      </div>
    </div>
  )
}

export default Error