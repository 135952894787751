import { useContext } from "react"
import { LanguageContext } from "./languages"

interface TextIdProp {
  tid: string
}

const Text = ({tid}: TextIdProp) => {
  const context = useContext(LanguageContext);
  return <>{context.dictionary[tid]}</> 
}

export default Text
